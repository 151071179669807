<template>
  <b-row>
    <b-col lg="4" sm="12" class="mb-2">
      <input
          type="file"
          id="img1"
          ref="img1"
          class="d-none"
          @change="onFileChange($event, 'img1')"
          accept="image/*"
      />
      <div class="p-2 d-flex justify-content-center rounded position-relative"
           style="border: 1px #FF6F6F dashed;">
        <img src="@/assets/images/anh-mat-truoc-cccd.png" v-if="!item.img1" class="img-upload"/>
        <img :src="item.img1.image" v-else class="img-upload" />
        <div class="d-flex position-absolute" style="top:8rem" v-if="!item.img1">
          <!-- <button @click="$refs.img1.click()"
                  class="uploadButton register-upload-button mr-1 px-2 d-flex justify-content-around align-items-center">
            <img src="@/assets/images/register-upload-icon.svg">Tải ảnh lên
          </button> -->
          <button @click=" takePhoto($event,'img1')"
                  class="uploadButton showOnMobile-flex justify-content-around px-2 align-items-center take-photo-button">
            <img src="@/assets/images/take-photo-icon.png">Chụp ảnh
          </button>
        </div>
        <button class="position-absolute" style="top: -2rem;right: -1.5rem" @click=" removeImg($event,'img1')"><img
            src="@/assets/images/x-button-dktttb.png" style="width: 4rem; height: 4rem"></button>
      </div>
      <div class="text-center mt-2">
        <p style="font-size: 18px; font-weight: 500">Ảnh mặt trước của CCCD/CMND</p>
        <p style="font-size: 14px">
          <span style="color: red">Lưu ý: </span>Hình ảnh đủ sáng và RÕ NÉT, KHÔNG MỜ,
          KHÔNG MẤT GÓC, KHÔNG LÓA SÁNG
        </p>
      </div>
    </b-col>
    <b-col lg="4" sm="12" class="mb-2">
      <input
          type="file"
          id="img2"
          ref="img2"
          class="d-none"
          @change="onFileChange($event, 'img2')"
          accept="image/*"
      />
      <div class="p-2 d-flex justify-content-center rounded position-relative" style="border: 1px #FF6F6F dashed">
        <img src="@/assets/images/anh-mat-sau-cccd.png" v-if="!item.img2" class="img-upload"/>
        <img :src="item.img2.image" v-else class="img-upload"/>
        <div class="d-flex position-absolute" style="top:8rem" v-if="!item.img2">
          <!-- <button @click="$refs.img2.click()"
                  class=" uploadButton register-upload-button mr-1 px-2 d-flex justify-content-around align-items-center">
            <img src="@/assets/images/register-upload-icon.svg">Tải ảnh lên
          </button> -->
          <button @click=" takePhoto($event,'img2')"
                  class="uploadButton showOnMobile-flex justify-content-around px-2 align-items-center take-photo-button">
            <img src="@/assets/images/take-photo-icon.png">Chụp ảnh
          </button>
        </div>
        <button class="position-absolute" style="top: -2rem;right: -1.5rem" @click=" removeImg($event,'img2')"><img
            src="@/assets/images/x-button-dktttb.png" style="width: 4rem; height: 4rem"></button>
      </div>
      <div class="text-center mt-2">
        <p style="font-size: 18px; font-weight: 500">Ảnh mặt sau của CCCD/CMND</p>
        <p style="font-size: 14px">
          <span style="color: red">Lưu ý: </span>Hình ảnh đủ sáng và RÕ NÉT, KHÔNG MỜ,
          KHÔNG MẤT GÓC, KHÔNG LÓA SÁNG
        </p>
      </div>
    </b-col>
    <b-col lg="4" sm="12">
      <input
          type="file"
          id="img3"
          ref="img3"
          class="d-none"
          @change="onFileChange($event, 'img3')"
          accept="image/*"
      />
      <div class="p-2 d-flex justify-content-center rounded position-relative" style="border: 1px #FF6F6F dashed">
        <img src="@/assets/images/anh-chan-dung.png" v-if="!item.img3" class="img-upload"/>
        <img :src="item.img3.image" v-else class="img-upload" />
        <div class="d-flex position-absolute " style="top:8rem" v-if="!item.img3">
          <!-- <button @click="$refs.img3.click()"
                  class=" uploadButton register-upload-button mr-1 px-2 d-flex justify-content-around align-items-center">
            <img src="@/assets/images/register-upload-icon.svg">Tải ảnh lên
          </button> -->
          <button @click=" takePhoto($event,'img3')"
                  class="uploadButton showOnMobile-flex justify-content-around px-2 align-items-center take-photo-button">
            <img src="@/assets/images/take-photo-icon.png">Chụp ảnh
          </button>
        </div>
      </div>
      <div class="text-center mt-2">
        <p style="font-size: 18px; font-weight: 500">Ảnh chân dung</p>
        <p style="font-size: 14px">
          <span style="color: red">Lưu ý: </span>Hình ảnh đủ sáng, KHÔNG MỜ, KHÔNG NHẮM MẮT,
          KHÔNG LÓA SÁNG và trang phục lịch sự
        </p>
      </div>
      <button class="position-absolute" style="top: -2rem;right: -0.5rem" @click=" removeImg($event,'img3')"><img
          src="@/assets/images/x-button-dktttb.png" style="width: 4rem; height: 4rem"></button>
    </b-col>
    <div
        class="zindex-3 bg-white p-1 rounded cropperModal"
        v-if="isShowCropperModal">
      <b-row class="justify-content-end pr-2">
        <button
            type="button"
            data-tw-dismiss="modal"
            class="mb-1"
            style=""
            @click="closeWithoutEdit()"
        >
          <img
              src="@/assets/images/close-upload-icon.svg"  class="closeWithoutEdit__Img">
        </button>
      </b-row>

      <cropper
          class="cropper"
          :src="currentImg"
          :stencil-props="{

	}"
          ref="cropper"
          @change="change"
      ></cropper>
      <div class="vertical-buttons">
        <div
            title="Flip Horizontal"
            class="square-button"
            @click="flip(true, false)"
        >
          <img src="@/assets/images/flip-horizontal.svg"/>
        </div>
        <div
            title="Flip Vertical"
            class="square-button"
            @click="flip(false, true)"
        >
          <img src="@/assets/images/flip-vertical.svg"/>
        </div>
        <div
            title="Rotate Clockwise"
            class="square-button"
            @click="rotateImg(90)"
        >
          <img src="@/assets/images/rotate-clockwise.svg"/>
        </div>
        <div
            title="Rotate Counter-Clockwise"
            class="square-button"
            @click="rotateImg(-90)"
        >
          <img src="@/assets/images/rotate-counter-clockwise.svg"/>
        </div>
      </div>

      <b-row class="justify-content-end ">
        <button
            type="button"
            data-tw-dismiss="modal"
            class="absolute btn-sm  mt-lg-3 mr-3 text-white rounded"
            style="background-color: #ED1F24;font-size: 20px"
            @click="saveCropImage()"
        >
          Xác Nhận
        </button>
      </b-row>
    </div>
    <b-modal
        body-class="p-0"
        size="lg"
        hide-footer
        id="modal-cam"
        title=""
        hide-header
        centered
    >
      <vue-web-cam
          @started="onStarted"
          @camera-change="onCameraChange"
          @cameras="onCameras"
          @stopped="onStopped"
          height="calc(100vw * 1.3)"
          width="100%"
          :selectFirstDevice="true"
          :autoplay="true"
          ref="webcam"
      ></vue-web-cam>
      <div class="listButtonCamera d-flex justify-content-around">
        <button
            class="close1"
            @click="
                    isCapture = false;
                    $bvModal.hide('modal-cam');
                "
        >
          <img src="@/assets/images/close.svg" style="height: 2rem">
        </button>
        <button @click="capture()" class="photo1">
          <svg
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="30" cy="30" r="28.75" stroke="#ED1F24" stroke-width="2.5"/>
            <circle cx="30" cy="30.0001" r="25.5882" fill="#ED1F24"/>
          </svg>
        </button>
        <button @click="rotateCamera()" class="rotate1">
          <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="20" cy="20" r="20" fill="#C4C4C4"/>
            <path
                d="M30.5 20.5L27.5 20.5C27.5027 19.0148 27.0642 17.5622 26.2403 16.3265C25.4164 15.0908 24.244 14.1275 22.8719 13.5587C21.4998 12.99 19.9898 12.8415 18.5331 13.132C17.0765 13.4224 15.7389 14.1389 14.69 15.1904L12.575 13.0755C14.0435 11.607 15.9144 10.607 17.9513 10.2018C19.9882 9.79661 22.0996 10.0045 24.0183 10.7993C25.937 11.594 27.5769 12.9398 28.7307 14.6665C29.8844 16.3933 30.5001 18.4233 30.5 20.5V20.5ZM27.425 27.9245C25.9565 29.393 24.0856 30.393 22.0487 30.7982C20.0118 31.2034 17.9004 30.9955 15.9817 30.2007C14.063 29.406 12.4231 28.0602 11.2693 26.3335C10.1156 24.6067 9.49985 22.5767 9.5 20.5L12.5 20.5C12.4973 21.9852 12.9358 23.4377 13.7597 24.6735C14.5836 25.9092 15.7559 26.8725 17.1281 27.4413C18.5002 28.01 20.0102 28.1585 21.4669 27.868C22.9235 27.5776 24.2611 26.8611 25.31 25.8096L27.425 27.9245V27.9245ZM5 20.5L11 14.5004L17 20.5L5 20.5ZM23 20.5L35 20.5L29 26.4996L23 20.5Z"
                fill="black"
            />
          </svg>
        </button>
      </div>

    </b-modal>
  </b-row>

</template>

<script>
import {
  BButton,
  BCard,
  BCardHeader, BCardText,
  BCol, BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup, BImg, BInputGroup, BInputGroupAppend, BPagination,
  BRow, BTab, BTabs, TabsPlugin, VBTooltip
} from "bootstrap-vue";
import Select2 from "v-select2-component";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import {WebCam} from "vue-web-cam";
import StarRating from "vue-star-rating";
import Fuse from "fuse.js";
import OtpInput from "@bachdgvn/vue-otp-input";
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

const imageType = {
  img1: "frontCMND",
  img2: "backCMND",
  img3: "profilePicture",
};
export default {
  name: "IdentityVerify",
  components: {
    BCardHeader,
    Select2,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    FeatherIcon,
    BFormCheckbox,
    BCardText,
    BPagination,
    "vue-web-cam": WebCam,
    VBTooltip,
    StarRating,
    Fuse,
    BImg,
    TabsPlugin,
    BTabs,
    BTab,
    "v-otp-input": OtpInput,
    Cropper,

  },
  data() {
    return {
      currentImg: "",
      currentImgName: "",
      item: {},
      isShowCropperModal: false,
      isCapture: false,
      currentCapture: "",
      deviceIndex: 0,
      deviceId: null,
      devices: []

    }
  },
  methods: {
    change({coordinates, canvas}) {
      console.log(coordinates, canvas)
    },
    onFileChange(event, img) {
      event.preventDefault();
      const file = event.target.files ? event.target.files[0] : null;
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        this.item[img] = {
          image_name: file.name,
          image_size: file.size,
          image_type: file.type,
          image: bstr,
        };
        this.handleClickImage(img);
      };
      reader.readAsDataURL(file);
    },
    removeImg(event, img) {
      event.preventDefault();
      this.item[img] = null;
      this.$refs[img].value = null;
      this.$emit("remove-image", event, img);
      this.$forceUpdate()
    },
    handleClickImage(type) {
      this.currentImg = this.item[type].image;
      this.currentImgName = type;
      this.isShowCropperModal = true
    },
    flip(x, y) {
      const {image} = this.$refs.cropper.getResult();
      if (image.transforms.rotate % 180 !== 0) {
        this.$refs.cropper.flip(!x, !y);
      } else {
        this.$refs.cropper.flip(x, y);
      }
    },
    rotateImg(angle) {
      this.$refs.cropper.rotate(angle);
    },
    rotateCamera() {
      this.deviceIndex++;
      if (this.deviceIndex > this.devices.length - 1) {
        this.deviceIndex = 0;
      }
      var c = this.devices.find(
          (i) => i.deviceId != this.deviceId && this.devices.indexOf(i) >= this.deviceIndex
      );
      if (c != undefined) {
        this.$refs.webcam.changeCamera(c.deviceId);
      }
    },
    closeWithoutEdit() {
      this.isShowCropperModal = false
      this.upLoadImage(this.currentImg, imageType[this.currentImgName]);
    },
    async saveCropImage() {
      const cropImageResult = this.$refs.cropper.getResult().canvas.toDataURL();
      this.item[this.currentImgName].image = cropImageResult;
      console.log("check",imageType[this.currentImgName])
      this.upLoadImage(cropImageResult, imageType[this.currentImgName]);
      this.isShowCropperModal = false
    },
    upLoadImage(image, type) {
      this.$emit("upload-image", image, type);
    },
    onStarted(stream) {
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      console.log("On Camera Change Event", deviceId);
    },
    onCameras(cameras) {
      this.devices = cameras;
      cameras.forEach(e=>{
          console.log(e.label)
      })
      console.log("On Cameras Event", cameras);
    },
    onStopped() {
      this.isCapture = false;
    },
    takePhoto(event, img) {
      event.preventDefault();
      this.currentCapture = img;
      this.$bvModal.show("modal-cam");
    },
    capture(srcImg = null) {
      var img = srcImg ? srcImg : this.$refs.webcam.capture();
      this.item[this.currentCapture] = {
        image_name: this.currentCapture,
        image_type: 'image/jpeg',
        image: img,
      };
      console.log("check",imageType[this.currentCapture])
      this.upLoadImage(img,  imageType[this.currentCapture]);
      this.$forceUpdate();
      this.$bvModal.hide("modal-cam");
    },
  },
}
</script>

<style scoped>

.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}

.showOnMobile {
  display: none !important;
}

.showOnMobile-flex {
  display: flex !important;
}

.showOnPC {
  display: block !important;
}

.showOnPC-flex {
  display: flex !important;
}

.vertical-buttons {
  position: absolute;
  left: 3rem;
  top: 40%;
  transform: translateY(-50%);
}

.square-button {
  background: rgba(0, 0, 0, .4);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background .5s;
}

.img-upload {
  object-fit: contain;
  width: 35rem;
  height: 20rem
}

.cropperModal {
  position: fixed;
  width: 80rem;
  height: 55rem;
  top: 20%;
  left: 25%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cropper {
  margin-left: 2rem;
  width: 75rem;
  height: 40rem
}

.close1 {
  position: absolute;
  right: 1rem;
  top: 6px;
  font-weight: 400;
  color: red;
}

.uploadButton {
  font-weight: 600;
  font-size: 12px;
  width: 122.38px;
  height: 38.94px;
  border-radius: 40px;
}

.register-upload-button {
  color: white;
  background-color: #EA0029;
  border: 1px solid #EA0029;
}

.take-photo-button {
  color: #EA0029;
  background-color: white;
  border: 1px solid #EA0029;
}
.closeWithoutEdit__Img{
  width: 4rem;
  height: 4rem;
}
@media screen and (max-width: 1080px) {
  .cropperModal {
    width: 65rem;
    height: 35rem;
    top: 25rem;
    left: 10rem;
  }

  .vertical-buttons {
    left: 4rem;
    top: 55%;
  }

  .cropper {
    margin-left: 2rem;
    width: 60rem;
    height: 25rem;
    margin-bottom: 1rem;
  }
.closeWithoutEdit__Img{
  width: 3rem;
  height: 3rem;
}
  .col-xs-mobile {
    width: 25%;
  }

  .flex-column-mobile {
    flex-direction: column;
  }

  .ml-1-mobile {
    margin-left: 1rem;
  }

  .showOnMobile {
    display: block !important;
  }

  .showOnMobile-flex {
    display: flex !important;
  }

  .showOnPC {
    display: none !important;
  }

  .showOnPC-flex {
    display: none !important;
  }
  .hideAll {
    display: none !important;
  }
}

@media screen and (max-width: 450px) {
  .cropperModal {
    width: 33rem;
    height: 35rem;
    top: 25rem;
    left: 3rem;
  }

  .vertical-buttons {
    left: 1rem;
    top: 55%;
  }

  .cropper {
    margin-left: 0;
    height: 25rem;
    margin-bottom: 1rem;
  }

  .col-xs-mobile {
    width: 25%;
  }

  .flex-column-mobile {
    flex-direction: column;
  }

  .ml-1-mobile {
    margin-left: 1rem;
  }

  .showOnMobile {
    display: block !important;
  }

  .showOnMobile-flex {
    display: flex !important;
  }

  .showOnPC {
    display: none !important;
  }

  .showOnPC-flex {
    display: none !important;
  }
}
</style>