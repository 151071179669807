
const getMesErrorFaceMatching = (errorCode) => {
  const errors = {
    1: 'Upload ảnh bị lỗi khi dùng POST',
    2: 'Download ảnh bị lỗi khi dùng GET',
    3: 'Ảnh chụp giấy tờ không tìm thấy khuôn mặt, Bạn vui lòng kiểm tra và thực hiện lại nhé!',
    4: 'Hết số lượng request',
    5: 'Khi api_key hoặc api_secret sai',
    6: 'Loại format khai báo trong format_type không đúng với ảnh truyền vào',
  };
  return errorCode ? errors[errorCode] : null;
};

const getMesWarningFaceMatching = (warningCode) => {
  const warnings = {
    1: 'Giấy tờ tùy thân không nguyên vẹn, Bạn vui lòng kiểm tra lại nhé!',
    2: 'Ảnh chụp giấy tờ không hợp lệ do bản photocopy, Bạn vui lòng thực hiện chụp ảnh từ giấy tờ tùy thân gốc nhé!',
    3: 'Ảnh chụp giấy tờ tùy thân đã bị lóa, Bạn vui lòng chụp lại nhé!',
    4: 'Ảnh giấy tờ tùy thân không có mặt',
    5: 'Ảnh chụp giấy tờ không tìm thấy khuôn mặt, Bạn vui lòng kiểm tra và thực hiện lại nhé!',
    6: 'Ảnh chân dung có nhiều hơn 1 khuôn mặt, Bạn vui lòng thực hiện lại nhé!',
    7: 'Ảnh chân dung không hợp lệ, Bạn vui lòng không đeo kính đen để chụp ảnh nhé!',
    8: 'Ảnh chân dung không hợp lệ, Bạn vui lòng không đội mũ để chụp ảnh nhé!',
    9: 'Ảnh chân dung không hợp lệ, Bạn vui lòng không đeo khẩu trang để chụp ảnh nhé!',
    10: 'Ảnh chụp giấy tờ không hợp lệ do được chụp từ màn hình, thiết bị khác. Bạn vui lòng kiểm tra và thực hiện lại nhé! ',
    11: 'Ảnh chân dung không hợp lệ, Bạn vui lòng chụp khuôn mặt gần hơn nhé!',
    12: 'Ảnh chân dung không hợp lệ, Bạn vui lòng chụp khuôn mặt xa hơn chút nhé!',
  };
  return warningCode ? warnings[warningCode] : null;
};

const getMesErrorORC = (errorCode) => {
  const errors = {
    1: 'Giấy tờ tùy thân không nguyên vẹn, Bạn vui lòng kiểm tra lại nhé!',
    2: 'Download ảnh bị lỗi khi dùng GET',
    3: 'Upload ảnh bị lỗi khi dùng POST',
    4: 'Hết số lượng request',
    5: 'Khi api_key hoặc api_secret sai',
    6: 'Loại format khai báo trong format_type không đúng với ảnh truyền vào',
  };
  return errorCode ? errors[errorCode] : null;
};

const getMesWarningORC = (warningCode) => {
  const warnings = {
    1: 'Ảnh chụp giấy tờ tùy thân chưa rõ nét thông tin, Bạn vui lòng thực hiện chụp lại nhé!',
    2: 'Ảnh giấy tờ tùy thân là bản photocopy',
    3: 'Ảnh chụp giấy tờ tùy thân chưa rõ nét thông tin, Bạn vui lòng thực hiện chụp lại nhé!',
    4: 'MRZ code trên passport không đúng format',
    5: 'Ảnh chụp giấy tờ tùy thân bị cắt góc, Bạn vui lòng thực hiện lại nhé!',
    6: 'Ảnh chụp giấy tờ tùy thân bị cắt góc, Bạn vui lòng thực hiện lại nhé!',
  };
  return warningCode ? warnings[warningCode] : null;
};

export {
  getMesErrorFaceMatching,
  getMesWarningFaceMatching,
  getMesErrorORC,
  getMesWarningORC,
};

